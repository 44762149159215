<script>
import MetisMenu from "metismenujs/dist/metismenujs";
import { mapState } from "vuex";

export default {
  locales: {
    pt: {
      "Go to store": "Ir para a loja virtual",
      Menu: "Menu",
      Dashboard: "Painel",
      Register: "Cadastrar",
      Courses: "Cursos",
      Store: "Loja",
      Products: "Produtos",
      Cart: "Carrinho",
      "My Orders": "Meus Pedidos",
      Wallet: "Carteira",
      Deposit: "Depositar",
      Extract: "Extrato",
      Network: "Rede",
      Profit: "Rendimento",
      Activation: "Ativação",
      Balance: "Saldo",
      Transfer: "Transferir",
      Pay: "Pagar",
      Withdrawal: "Sacar",
      "Withdrawal History": "Histórico de Saque",
      Sponsored: "Indicados",
      Team: "Equipe",
      Binary: "Binário",
      Reports: "Relatórios",
      "Bonus Referral": "Bônus Indicação",
      "Bonus Team": "Bônus Equipe",
      "Bonus Shopping": "Bônus Compras",
      Graduation: "Graduação",
      Support: "Suporte",
      Downloads: "Downloads",
      FAQ: "FAQ",
      Tickets: "Tickets",
      Points: "Pontos",
      "Monthly Activation": "Ativação Mensal",
    },
    es: {
      "Go to store": "Ir a la tienda virtual",
      Menu: "Menu",
      Dashboard: "Panel",
      Register: "Registro",
      Courses: "Cursos",
      Store: "Tienda",
      Products: "Productos",
      Cart: "Cesta",
      "My Orders": "Mis Pedidos",
      Wallet: "Cartera",
      Deposit: "Depositar",
      Extract: "Extracto",
      Network: "Red",
      Profit: "Rendimiento",
      Activation: "Activación",
      Balance: "Saldo",
      Transfer: "Transferir",
      Pay: "Pagar",
      Withdrawal: "Retirada",
      "Withdrawal History": "Historia de Retirada",
      Sponsored: "Patrocinados",
      Team: "Equipo",
      Binary: "Binario",
      Reports: "Informes",
      "Bonus Referral": "Bonus Patrocinio",
      "Bonus Team": "Bônus Equipo",
      "Bonus Shopping": "Bônus Compras",
      Graduation: "Graduación",
      Support: "Soporte",
      Downloads: "Descargas",
      FAQ: "FAQ",
      Tickets: "Tickets",
      Points: "Puntos",
      "Monthly Activation": "Activación Mensual",
    },
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
  },
  mounted: function () {
    document.body.setAttribute("data-sidebar", "light");
    // eslint-disable-next-line no-unused-vars
    var menuRef = new MetisMenu("#side-menu");
    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      if (parent) {
        parent.classList.add("mm-active");
        const parent2 = parent.parentElement.closest("ul");
        if (parent2 && parent2.id !== "side-menu") {
          parent2.classList.add("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("mm-active");
            var childAnchor = parent3.querySelector(".has-arrow");
            var childDropdown = parent3.querySelector(".has-dropdown");
            if (childAnchor) childAnchor.classList.add("mm-active");
            if (childDropdown) childDropdown.classList.add("mm-active");

            const parent4 = parent3.parentElement;
            if (parent4) parent4.classList.add("mm-show");
            const parent5 = parent4.parentElement;
            if (parent5) parent5.classList.add("mm-active");
          }
        }
      }
    }
  },
  methods: {
  },
};
</script>

<template>
  <div id="sidebar-menu" class="pt-0">
    <ul id="side-menu" class="metismenu list-unstyled">
      <li class="bg-soft-warning">
        <router-link tag="a" to="/store/featured" class="side-nav-link-ref border-0 py-2 mt-1">
          <i class="bx bx-cart"></i>
          <span class="notranslate">{{ t("Loja Virtual") }}</span>
        </router-link>
      </li>
      <li v-if="account.user.franchise.status == 'registered'" class="bg-soft-dark mt-1">
        <router-link tag="a" to="/franchise" class="side-nav-link-ref border-0 py-2">
          <i class="bx bx-building-house"></i>
          <span class="notranslate">{{ t("Franquia") }}</span>
        </router-link>
      </li>
      <li class="menu-title">{{ t("Menu") }}</li>
      <li>
        <router-link tag="a" to="/" class="side-nav-link-ref">
          <i class="bx bx-home-circle"></i>
          <span class="notranslate">{{ t("Dashboard") }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" :to="'/register/' + account.user.username" class="side-nav-link-ref">
          <i class="bx bxs-user-plus"></i>
          <span class="notranslate">{{ t("Register") }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/courses" class="side-nav-link-ref">
          <i class="bx bxs-graduation"></i>
          <span class="notranslate">{{ t("Courses") }}</span>
        </router-link>
      </li>
      <li class="menu-title">{{ t("Wallet") }}</li>
      <li>
        <router-link tag="a" to="/wallet/extract" class="side-nav-link-ref">
          <i class="bx bx-list-check"></i>
          <span class="notranslate">{{ t("Extract") }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/wallet/transfer" class="side-nav-link-ref">
          <i class="bx bx-transfer-alt"></i>
          <span class="notranslate">{{ t("Transfer") }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/wallet/pay" class="side-nav-link-ref">
          <i class="bx bx-dollar-circle"></i>
          <span class="notranslate">{{ t("Pay") }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/wallet/withdrawal" class="side-nav-link-ref">
          <i class="bx bx-wallet-alt"></i>
          <span class="notranslate">{{ t("Withdrawal") }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/wallet/withdrawal/history" class="side-nav-link-ref">
          <i class="bx bx-wallet"></i>
          <span class="notranslate">{{ t("Withdrawal History") }}</span>
        </router-link>
      </li>
      <li class="menu-title">{{ t("Network") }}</li>
      <li>
        <router-link tag="a" to="/network/clients" class="side-nav-link-ref">
          <i class="bx bx-user"></i>
          <span class="notranslate">{{ t("Clientes") }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/network/sponsored" class="side-nav-link-ref">
          <i class="bx bx-user"></i>
          <span class="notranslate">{{ t("Sponsored") }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/network/team" class="side-nav-link-ref">
          <i class="bx bx-group"></i>
          <span class="notranslate">{{ t("Team") }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/network/unilevel" class="side-nav-link-ref">
          <i class="bx bx-list-ol"></i>
          <span class="notranslate">{{ t("Unilevel") }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/network/matriz" class="side-nav-link-ref">
          <i class="bx bx-git-merge" style=""></i>
          <span class="notranslate">{{ t("Matriz") }}</span>
        </router-link>
      </li>
      <li class="menu-title">{{ t("Reports") }}</li>
      <li>
        <router-link tag="a" to="/reports/ecommerce" class="side-nav-link-ref">
          <i class="bx bx-line-chart"></i>
          <span class="notranslate">E-commerce</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/reports/indicacao" class="side-nav-link-ref">
          <i class="bx bx-line-chart"></i>
          <span class="notranslate">Indicação</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/reports/atividade" class="side-nav-link-ref">
          <i class="bx bx-line-chart"></i>
          <span class="notranslate">Atividade</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/reports/graduation" class="side-nav-link-ref">
          <i class="bx bx-line-chart"></i>
          <span class="notranslate">{{ t("Graduação") }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/reports/premiations" class="side-nav-link-ref">
          <i class="bx bx-line-chart"></i>
          <span class="notranslate">Premiações</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/reports/points" class="side-nav-link-ref">
          <i class="bx bx-line-chart"></i>
          <span class="notranslate">Pontos</span>
        </router-link>
      </li>
      <li class="menu-title">{{ t("Support") }}</li>
      <li>
        <router-link tag="a" to="/downloads" class="side-nav-link-ref">
          <i class="bx bx-download"></i>
          <span class="notranslate">{{ t("Downloads") }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/faq" class="side-nav-link-ref">
          <i class="bx bx-question-mark"></i>
          <span class="notranslate">{{ t("FAQ") }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/tickets" class="side-nav-link-ref">
          <i class="bx bx-conversation"></i>
          <span class="notranslate">{{ t("Tickets") }}</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.py-2 {
  padding: 0.9rem 1.5rem 0.7rem !important;
}
.py-2 i,
.py-2 span {
  line-height: 0px !important;
  padding-bottom: 0 !important;
}
.py-2 i {
  margin-top: -4px;
}
</style>